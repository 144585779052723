export const menuItems = [
  {
    name: "demos",
    label: "Demos",
    items: [
      { name: "", label: "Agency" },
      { name: "job-directory", label: "Job Directory" },
      { name: "co-working", label: "Co-working" },
      { name: "saas-subscription", label: "SaaS Subscription" },
      { name: "ecommerce", label: "ECommerce" },
      { name: "web-application", label: "Web Application" },
      { name: "video-conference", label: "Video Conference" },
      { name: "mobile-app", label: "Mobile App" },
      { name: "product", label: "Product Landing" },
      { name: "b2b", label: "B2B" },
      { name: "consultation", label: "Consultation" },
      { name: "telemedicine", label: "Telemedicine" },
    ],
  },
  {
    name: "pages",
    label: "Pages",
    items: [
      { name: "about", label: "About" },
      {
        name: "career",
        label: "Career",
        items: [
          { name: "job-openings", label: "Job openings" },
          { name: "job-details", label: "Job Details" },
        ],
      },
      {
        name: "pricing",
        label: "Pricing",
        items: [
          { name: "pricing1", label: "Pricing 01" },
          { name: "pricing2", label: "Pricing 02" },
          { name: "pricing3", label: "Pricing 03" },
        ],
      },
      {
        name: "contact",
        label: "Contact",
        items: [
          { name: "contact1", label: "Contact 01" },
          { name: "contact2", label: "Contact 02" },
          { name: "contact3", label: "Contact 03" },
        ],
      },
      {
        name: "account",
        label: "Account",
        items: [
          { name: "signin", label: "Signin" },
          { name: "signup", label: "Signup" },
          { name: "reset-pass", label: "Reset Password" },
        ],
      },

      {
        name: "utility",
        label: "Utility",
        items: [{ name: "terms-conditions", label: "Terms & Conditions" }],
      },
      {
        name: "ecommerce",
        label: "eCommerce",
        items: [
          { name: "product-details", label: "Product Details" },
          { name: "cart", label: "Cart" },
          { name: "checkout", label: "Checkout" },
        ],
      },
    ],
  },
  {
    name: "https://uxtheme.net/product-support/",
    label: "Support",
    isExternal: true,
  },
];
